import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/unittestbot.github.io/unittestbot.github.io/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "explore-test-suites"
    }}>{`Explore test suites`}</h2>
    <h3 {...{
      "id": "view-clustered-tests"
    }}>{`View clustered tests`}</h3>
    <p>{`Even before running tests you have them clustered into suites. Analyze them to see failing tests, explicitly thrown exceptions, and successful executions.`}</p>
    <p>{`These clusters are marked as `}<em parentName="p">{`Regions`}</em>{` in test source code. To view all the suites at once and navigate between them, press `}<strong parentName="p">{`Alt+7`}</strong>{` to open Structure tool window.`}</p>
    <p>{`The typical suites are:`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Fuzzer`}</em></li>
      <li parentName="ul"><em parentName="li">{`Symbolic execution engine`}</em>{`,`}</li>
    </ul>
    <p>{`each divided into:`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Successful executions`}</em></li>
      <li parentName="ul"><em parentName="li">{`Explicitly thrown unchecked exceptions`}</em></li>
      <li parentName="ul"><em parentName="li">{`Error suite`}</em></li>
    </ul>
    <p>{`Find the example below:`}</p>
    <p><img alt="Screenshot of UnitTestBot output structure, showing test suites" src="https://user-images.githubusercontent.com/64418523/193044976-c83d990d-3211-4218-8c07-57d628ccf780.PNG" /></p>
    <p>{`Dive into these suites to find test methods with detailed descriptions.`}</p>
    <h3 {...{
      "id": "read-test-descriptions"
    }}>{`Read test descriptions`}</h3>
    <p><img alt="test_description" src="https://user-images.githubusercontent.com/64418523/193581057-28d22cbf-d6c4-4c2e-b628-f0d84b991366.PNG" /></p>
    <p>{`Each test contains:`}</p>
    <h4 {...{
      "id": "javadoc-comment"
    }}>{`Javadoc comment`}</h4>
    <p>{`Briefly describes test execution path.`}</p>
    <ul>
      <li parentName="ul">{`See `}<a parentName="li" {...{
          "href": "https://github.com/UnitTestBot/UTBotJava/wiki/Fine-tune-test-generation#javadoc-comment-style"
        }}>{`Javadoc comment style`}</a>{` for configuring the `}<strong parentName="li">{`Plain
text`}</strong>{` or `}<strong parentName="li">{`Structured via custom Javadoc tags`}</strong>{` view.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.jetbrains.com/help/idea/working-with-code-documentation.html#toggle-rendered-view"
        }}>{`Render Javadoc comments`}</a>{` to get more clear and structured view.`}</li>
      <li parentName="ul">{`Jump to the method-under-test right from the comment.`}</li>
    </ul>
    <h4 {...{
      "id": "testing-framework-annotations"
    }}>{`Testing framework annotations`}</h4>
    <p>{`Provide a super short test method description inside:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`For JUnit 5: `}<inlineCode parentName="p">{`@DisplayName("\\<short description>")`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`For TestNG: `}<inlineCode parentName="p">{`@Test(description = "\\<short description>")`}</inlineCode><br></br>{`
(For JUnit 4 there are no descriptions right in the annotations.)`}</p>
        <p parentName="li">{`   `}<em parentName="p"><strong parentName="em">{`Disabled (or sandboxed) tests`}</strong></em></p>
        <p parentName="li">{`   Sometimes the tests with the `}<em parentName="p">{`@Disabled`}</em>{` annotation and a stack trace appear in the output:`}</p>
        <p parentName="li">{`   `}<inlineCode parentName="p">{`@Disabled("Disabled due to sandbox")`}</inlineCode></p>
        <p parentName="li">{`   Keep calm: it is a feature, not a bug. These tests were sandboxed, i.e. interrupted, because they tried to execute potentially unsafe code.`}</p>
        <p parentName="li">{`   The most common forbidden actions are:`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`working with files (read, write, create, delete),`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`connecting to sockets,`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`invoking `}<inlineCode parentName="p">{`System.exit()`}</inlineCode>{`,`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`accessing system properties or JVM properties,`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`using reflection.`}</p>
            <p parentName="li">{`You can `}<strong parentName="p">{`add permissions`}</strong>{` in `}<inlineCode parentName="p">{`~\\.utbot\\sandbox.policy`}</inlineCode>{`. Create this file manually.`}</p>
            <p parentName="li">{`You can refer to the `}<a parentName="p" {...{
                "href": "https://docs.oracle.com/javase/1.5.0/docs/guide/security/spec/security-spec.doc3.html"
              }}>{`Full list of permissions`}</a>{`.`}</p>
            <p parentName="li">{`If you are sure you want the code to be executed as is (`}<strong parentName="p">{`including the unsafe operations!`}</strong>{`) you can `}<strong parentName="p">{`turn sandboxing off`}</strong>{`: add `}<inlineCode parentName="p">{`AllPermission`}</inlineCode>{`  to `}<inlineCode parentName="p">{`~\\.utbot\\sandbox.policy`}</inlineCode>{`. Be careful!`}</p>
            <p parentName="li">{`If you remove the `}<em parentName="p">{`@Disabled`}</em>{` annotation and run the test, it will be run without creating a sandbox.`}</p>
          </li>
        </ul>
      </li>
    </ul>
    <h4 {...{
      "id": "test-method-name"
    }}>{`Test method name`}</h4>
    <p>{`Usually looks like `}<inlineCode parentName="p">{`testMethodUnderTestName`}</inlineCode>{`. Sometimes it is accompanied by explanatory supplements: e.g.,
`}<inlineCode parentName="p">{`ByFuzzer`}</inlineCode>{`, `}<inlineCode parentName="p">{`ReturnsOneWithCornerCase`}</inlineCode>{`, or others.`}</p>
    <h4 {...{
      "id": "test-method-body"
    }}>{`Test method body`}</h4>
    <p>{`You can jump to the method-under-test from here too.`}</p>
    <h3 {...{
      "id": "run-tests-and-view-coverage"
    }}>{`Run tests and view coverage`}</h3>
    <p>{`There are multiple ways to `}<a parentName="p" {...{
        "href": "https://www.jetbrains.com/help/idea/performing-tests.html"
      }}>{`run tests`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.jetbrains.com/help/idea/code-coverage.html"
      }}>{`view coverage`}</a>{` in IntelliJ IDEA. All of them apply to UnitTestBot results — feel free to choose any.`}</p>
    <p>{`If you are not satisfied with coverage, try to `}{`[Experiment with test generation]`}{`(Fine-tune test generation.md#Experiment with test generation to reach higher coverage).`}</p>
    <h3 {...{
      "id": "get-sarif-reports"
    }}>{`Get SARIF reports`}</h3>
    <h4 {...{
      "id": "what-is-sarif"
    }}>{`What is SARIF?`}</h4>
    <p>{`It is the `}<em parentName="p">{`Static Analysis Results Interchange Format`}</em>{` — a standard, JSON-based format for the output of static analysis tools.`}</p>
    <p>{`Every static analysis tool has its own output format, and these formats usually have little in common. SARIF provides a universal output format, which makes it possible to create common tooling, such as viewers, bug filers, metrics calculators, etc.`}</p>
    <p>{`For more information please refer to `}<a parentName="p" {...{
        "href": "https://github.com/microsoft/sarif-tutorials/blob/main/README.md"
      }}>{`SARIF Tutorials`}</a>{`.`}</p>
    <h4 {...{
      "id": "how-to-get-sarif-report-in-unittestbot"
    }}>{`How to get SARIF report in UnitTestBot`}</h4>
    <p>{`By default, UnitTestBot creates SARIF reports for each test generation.`}</p>
    <p><em parentName="p">{`Note:`}</em>{` if you enable `}<strong parentName="p">{`Parameterized tests`}</strong>{` in the `}<strong parentName="p">{`Generate Tests with UnitTestBot`}</strong>{` window, SARIF report is unavailable for now.`}</p>
    <p>{`You can find SARIF reports in the `}<inlineCode parentName="p">{`resources`}</inlineCode>{` directory near `}<strong parentName="p">{`Test sources root`}</strong>{`. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      